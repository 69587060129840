/* eslint-disable prettier/prettier */
import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import routes from "../../homeRoutes"
import cx from "classnames"
import logo from "assets/img/logo-white.svg"
import image from "assets/img/sidebar-2.jpg"
import Footer from "components/Footer/Footer.jsx"
import Sidebar from "components/Sidebar/Sidebar.jsx"
import "perfect-scrollbar/css/perfect-scrollbar.css"
import appStyle from "assets/jss/appStyle.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Avatar from "@material-ui/core/Avatar"
import ImageIcon from "@material-ui/icons/Image"
import WorkIcon from "@material-ui/icons/Work"
import BeachAccessIcon from "@material-ui/icons/BeachAccess"
import Divider from "@material-ui/core/Divider"
import brown from "@material-ui/core/colors/brown"
import ButtonBase from "@material-ui/core/ButtonBase"
import ReactButton from "components/CustomButtons/Button.jsx"
import CustomButtons from "components/CustomButtons/Button.jsx"
import Tooltip from "@material-ui/core/Tooltip"

// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import NavPills from "components/NavPills/NavPills.jsx"
import Accordion from "components/Accordion/AccordionChallenge"
import Card from "components/Card/Card.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import CardBody from "components/Card/CardBody.jsx"
import Dashboard from "@material-ui/icons/Dashboard"
import Schedule from "@material-ui/icons/Schedule"

import helper from "../../helper"
import cookieHelper from "../../cookiesHelper"

import CreateChallengeUI from "../Pages/CreateChallengePage.jsx"
import { twitterColor } from "assets/jss/material-dashboard-pro-react"
import combineStyles from "utils/combineStyle"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import { Button, Typography } from "@material-ui/core"
import EditIcon from "@material-ui/icons/EditRounded"
import buttonsStyle from "assets/jss/material-dashboard-pro-react/views/buttonsStyle.jsx"
import { Redirect, NavLink } from "react-router-dom"
import CardFooter from "components/Card/CardFooter"
import backgroundImage from "assets/img/BIC-wallpaper.webp"
import authentication from "../../authentication"
import constant from "../../constants"
import ErrorPage from "views/Pages/ErrorPage"
import SimpleBreadcrumbs from "../../components/SimpleBreadcumbs.jsx"
import sidebarStyle from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.jsx"

import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state"

import ReactHtmlParser from 'html-react-parser'
import Build from "@material-ui/icons/Build"
import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"
import userServices from "dataServices/userServices"
import challengeServices from "dataServices/challengeServices"
import timeUtils from "../../utils/timeUtils"
import TreeMenu from "react-simple-tree-menu"
import CustomDropdown from "components/CustomDropdown/CustomDropdown.jsx"
import ManageChallengeMenu from "./ManageChallengeMenu"

import DropDownMenu from "components/CustomDropdown/DropDownMenu"
import BookIcon from "@material-ui/icons/Book"

import classnames from "classnames"

import PhaseUtils from "utils/phaseUtils"
import FontOverridePatch from "utils/FontOverridePatch"
import constants from "../../constants"

class DashboardPage extends Component {
	constructor(props) {
		super(props)
		this.state = {
			challengeId: "",
			mobileOpen: false,
			miniActive: false,
			image: image,
			color: "blue",
			bgColor: "black",
			hasImage: true,
			fixedClasses: "dropdown",
			showErrorComponent: false,
			userId: "",
			challenges: [],
			draftChallenges: [],
			challengesWithPendingInvitation: [],
			redirect: false,
			CanGetAllChallenge: false,
			CanCreateOrUpdateEvaluationTeam: false,
			CanCreateOrUpdateManageChallengeInvitation: false,
			CanCreateOrUpdateEvaluationScore: false,
			CanCreateOrUpdateEvaluationCriteria: false,
			CanCreateChallenge: false,
			showChallengeBuilder: false,
			selectedChallenge: null,
			CanManageChallenge: false,
			anchorEl: null,
			challengeCardTitle: "My Challenges",
			draftChallengeCardTitle: "My Draft Challenges",
			challengesWithPendingInvitationsCardTitle: "My Challenges with pending invitations",
			canGetFinalLeaderboardForChallenge: false,
			mostRecentChallenge: null,
			activeRow: null
		}
		this.getMyChallenges = this.getMyChallenges.bind(this)
	}

	fetchCurrentUserProfile = () => {
		this.props.setLoadingSpinner()
		return userServices
			.getCurrentUser(`userId username email name`)
			.then((data) => {
				console.log("Current User", data)
				this.props.resetLoadingSpinner()
				return data
			})
			.catch((err) => {
				console.log("Error getting current User", err)
				this.props.resetLoadingSpinner()
				return null
			})
	}

	getUserProfile(userId) {
		this.props.setLoadingSpinner()
		return userServices
			.getUserProfileById(userId, `userId name roles{ RoleId challengeId teamId }`)
			.then((data) => {
				this.props.resetLoadingSpinner()
				return data
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error getting userProfile", err)
				return null
			})
	}

	fetchChallenges = () => {
		this.props.setLoadingSpinner()
		return challengeServices
			.getMyChallenges()
			.then((data) => {
				console.log("Challenges = ", data)
				this.props.resetLoadingSpinner()
				return data
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error getting challlenges", err)
				return null
			})
	}

	fetchDraftChallenges = () => {
		this.props.setLoadingSpinner()
		return challengeServices
			.getMyDraftChallenges()
			.then((data) => {
				console.log("Draft challenges = ", data)
				this.props.resetLoadingSpinner()
				return data
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error getting draft challlenges", err)
				return null
			})
	}

	fetchChallengesWithPendingInvitations = () => {
		return challengeServices
			.getChallengesWithPendingInvitations()
			.then((data) => {
				console.log("Challenges with pending invitations = ", data)
				return data
			})
			.catch((err) => {
				console.log("Error getting challlenges with pending invitations, err =", err.message)
				return null
			})
	}

	processPhaseData = (challenges) => {
		let tempchallenges = challenges
		var today = new Date()
		var date = today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear()
		let i
		for (i in tempchallenges) {
			let nextPhase = PhaseUtils.getNextPhase(tempchallenges[i].challengeDetails.phases)
			let currentPhase = "Not Available" //PhaseUtils.getCurrentPhase(tempchallenges[i].challengeDetails.phases)
			let challenge = tempchallenges[i]

			if (challenge.challengeDetails.currentPhase) {
				currentPhase = challenge.challengeDetails.currentPhase.phaseTitle
				let remainingDays = PhaseUtils.getNextPhaseCountDownValue(
					challenge.challengeDetails.currentPhase
				)
				if (remainingDays == "") tempchallenges[i].remainingDays = Number.MAX_SAFE_INTEGER
				else tempchallenges[i].remainingDays = remainingDays
			} else {
				tempchallenges[i].remainingDays = Number.MAX_SAFE_INTEGER
			}
			if (nextPhase) {
				tempchallenges[i].nextPhase = nextPhase.title
			} else {
				tempchallenges[i].nextPhase = "Coming soon"
			}

			tempchallenges[i].currentPhase = currentPhase
		}
		if (tempchallenges) {
			tempchallenges.sort(function (a, b) {
				return a.remainingDays > b.remainingDays ? 1 : a.remainingDays < b.remainingDays ? -1 : 0
			})
		}

		return tempchallenges
	}

	fetchAllData = (userId) => {
		Promise.all([
			this.getUserProfile(userId),
			this.fetchChallenges(),
			this.fetchDraftChallenges(),
			this.fetchChallengesWithPendingInvitations()
		])
			.then((result) => {
				console.log("multiple api calls ", result)
				console.log("Both APIs call okay")
				let draftChallenges = result[2] ? result[2] : []
				let challenges = result[1] ? result[1] : []
				let userProfile = result[0] ? result[0] : null
				let challengesWithPendingInvitation = result[3] ? result[3] : []
				let i
				for (i in userProfile.roles) {
					if (userProfile.roles[i].RoleId == "superAdmin") {
						this.setState({ challengeCardTitle: "All Challenges" })
					}
				}

				challenges = this.processPhaseData(challenges)
				draftChallenges = this.processPhaseData(draftChallenges)
				challengesWithPendingInvitation = this.processPhaseData(challengesWithPendingInvitation)

				this.setState(
					{
						challenges: challenges,
						draftChallenges: draftChallenges,
						challengesWithPendingInvitation: challengesWithPendingInvitation
					},
					() => {
						this.setActiveRow()
					}
				)
			})
			.catch((err) => {
				console.log("Error:", err.message)
			})
	}

	getMyChallenges() {
		this.fetchCurrentUserProfile().then((data) => {
			if (data) {
				this.setState(
					{
						userId: data.userId
					},
					() => {
						this.fetchAllData(data.userId)
					}
				)
			} else {
				this.setState({ showErrorComponent: true })
				this.props.setRedirectOnError("/home/index")
			}
		})
	}

	handleOnClickLearnMore = (challengeIdOrSlug) => {
		this.props.history.push("/dashboard/challenge-details/" + challengeIdOrSlug)
	}

	setMostRecentChallenge = (mostRecentChallenge) => {
		this.setState({
			mostRecentChallenge: mostRecentChallenge
		})
	}

	componentDidMount() {
		this.props.resetBreadCrumbStack()
		this.props.pushBreadCrumbStack({ name: "Dashboard", link: "/dashboard/index" })
		console.log(this.props.breadCrumbStack)
		if (this.props.location.hash) {
			let decodedHash = decodeURI(this.props.location.hash)
			let hashValues = decodedHash.split("#")[1].split("&")
			let valueArray = new Array()
			var data = {}
			for (var i = 0; i < hashValues.length; i++) {
				let tempArray = hashValues[i].split("=")
				valueArray[tempArray[0]] = tempArray[1]
				data[tempArray[0]] = tempArray[1]
			}
			cookieHelper.set(constants.Settings.LoggedInCookieName, data)
			this.props.setLoggedInUserData(data)
			window.location.href = window.location.origin + window.location.pathname
		} else {
			let data = cookieHelper.get(constants.Settings.LoggedInCookieName)
			if (data) {
				this.props.setLoggedInUserData(data)

				let requiredPermissionsObjects = [
					{ permissionName: constant.Functionalities.CanGetAllChallenge, objectId: null },
					{ permissionName: constant.Functionalities.CanCreateChallenge, objectId: null },
					{
						permissionName: constant.Functionalities.CanCreateOrUpdateEvaluationTeam,
						objectId: null
					},
					{
						permissionName: constant.Functionalities.CanCreateOrUpdateManageChallengeInvitation,
						objectId: null
					},
					{
						permissionName: constant.Functionalities.CanCreateOrUpdateEvaluationScore,
						objectId: null
					},
					{
						permissionName: constant.Functionalities.CanCreateOrUpdateEvaluationCriteria,
						objectId: null
					},
					{ permissionName: constant.Functionalities.CanManageChallenge, objectId: null },
					{
						permissionName: constant.Functionalities.CanGetFinalLeaderboardForChallenge,
						objectId: null
					}
				]
				authentication
					.hasMultiplePermissions(requiredPermissionsObjects)
					.then((permissionResponseObject) => {
						if (permissionResponseObject) {
							permissionResponseObject.map((object) => {
								if (
									object.permissionName == constant.Functionalities.CanGetAllChallenge &&
									object.permissionStatus
								)
									this.setState({ CanGetAllChallenge: true })
								else if (
									object.permissionName ==
										constant.Functionalities.CanCreateOrUpdateEvaluationTeam &&
									object.permissionStatus
								)
									this.setState({ CanCreateOrUpdateEvaluationTeam: true })
								else if (
									object.permissionName == constant.Functionalities.CanCreateChallenge &&
									object.permissionStatus
								)
									this.setState({ CanCreateChallenge: true })
								else if (
									object.permissionName ==
										constant.Functionalities.CanCreateOrUpdateManageChallengeInvitation &&
									object.permissionStatus
								)
									this.setState({ CanCreateOrUpdateManageChallengeInvitation: true })
								else if (
									object.permissionName ==
										constant.Functionalities.CanCreateOrUpdateEvaluationScore &&
									object.permissionStatus
								)
									this.setState({ CanCreateOrUpdateEvaluationScore: true })
								else if (
									object.permissionName ==
										constant.Functionalities.CanCreateOrUpdateEvaluationCriteria &&
									object.permissionStatus
								)
									this.setState({ CanCreateOrUpdateEvaluationCriteria: true })
								else if (
									object.permissionName == constant.Functionalities.CanManageChallenge &&
									object.permissionStatus
								)
									this.setState({ CanManageChallenge: true })
								else if (
									object.permissionName ==
										constant.Functionalities.CanGetFinalLeaderboardForChallenge &&
									object.permissionStatus
								)
									this.setState({ canGetFinalLeaderboardForChallenge: true })
							})
						} else this.props.showAlert("warning", "Ooops!", "Error getting required permissions")
					})
					.catch((err) => {
						console.log("Err =", err.message)
					})
				this.getMyChallenges()
			} else {
				this.props.setRedirectOnError("/home/index")
				this.setState({ showErrorComponent: true })
			}
		}
	}

	componentWillUnmount() {
		helper.setPageTitle("Home | BestInCrowd")
	}

	setActiveRow = () => {
		let activeRow
		let challenges = this.state.challenges
		if (this.state.mostRecentChallenge && this.state.mostRecentChallenge.challengeId) {
			let mostRecentChallenge = this.state.mostRecentChallenge.challengeId
			challenges.map((challenge, index) => {
				if (challenge.challengeId == mostRecentChallenge) activeRow = index
			})
		}
		this.setState({
			activeRow: activeRow
		})
	}

	render() {
		const { classes, ...rest } = this.props
		let colorTheme = this.state.CanCreateChallenge ? null : "gmgTheme"
		console.log("color theme =", colorTheme)

		helper.setPageTitle("Dashboard | BestInCrowd")
		return (
			<div>
				{this.state.showErrorComponent ? (
					<div>
						<SimpleBreadcrumbs breadLinkData={[{ name: "Error", link: "/dashboard/index" }]} />
						<GridContainer direction="row" justify="space-evenly" alignItems="center">
							<GridItem>
								<ErrorPage
									errorCode={403}
									message={"Access Denied! :("}
									description={"You are not logged in, please login to see this page"}
								/>
							</GridItem>
						</GridContainer>
					</div>
				) : this.state.CanGetAllChallenge ? (
					<div>
						<SimpleBreadcrumbs breadLinkData={this.props.breadCrumbStack} />
						<GridContainer>
							<GridItem xs={12} sm={12} md={12} lg={this.state.showChallengeBuilder ? 6 : 6}>
								{this.state.CanGetAllChallenge ? (
									<Card profile={true} color={colorTheme} raised>
										<CardHeader
											color={colorTheme}
											className={classnames({
												[classes.cardHead]: true || this.state.CanCreateChallenge
											})}
										>
											<GridContainer direction="row" justify="space-between" alignItems="center">
												<GridItem xs={12} sm={6} md={6}>
													<h5
														style={{
															textAlign: "left",
															marginLeft: "0px",
															paddingLeft: "0px",
															fontSize: "1em"
														}}
													>
														{this.state.challengeCardTitle}
													</h5>
												</GridItem>
												<GridItem
													xs={12}
													sm={6}
													md={6}
													style={{ textAlign: "right", marginRight: "0px", paddingRight: "0px" }}
												>
													{this.state.CanCreateChallenge ? (
														<CustomButtons
															color="twitter"
															className={classes.addChallengeButton}
															onClick={() =>
																this.setState({
																	showChallengeBuilder: true,
																	selectedChallenge: null
																})
															}
														>
															Add Challenge
														</CustomButtons>
													) : null}
												</GridItem>
											</GridContainer>
										</CardHeader>
										<CardBody
											className={classnames({ [classes.cardBody]: this.state.CanCreateChallenge })}
										>
											<Accordion
												color={colorTheme}
												id="dashboardList"
												className={classes.cardBody}
												active={this.state.activeRow}
												collapses={this.getCollapseView()}
											/>
										</CardBody>
									</Card>
								) : (
									<div></div>
								)}
							</GridItem>
							{this.state.showChallengeBuilder ? (
								<GridItem xs={12} sm={12} md={12} lg={6}>
									<CreateChallengeUI
										updateChallenge={this.getMyChallenges}
										parentChallenge={this.state.selectedChallenge}
										hideChallengeBuilder={() => {
											this.setState({
												showChallengeBuilder: false,
												selectedChallenge: null
											})
										}}
										setMostRecentChallenge={this.setMostRecentChallenge}
									/>
								</GridItem>
							) : null}
							{this.state.draftChallenges.length ? (
								<GridItem xs={12} sm={12} md={12} lg={this.state.showChallengeBuilder ? 6 : 6}>
									<Card profile={true} raised>
										<CardHeader className={classnames({ [classes.cardHead]: true })}>
											<GridContainer direction="row" justify="space-between" alignItems="center">
												<GridItem xs={12} sm={6} md={6}>
													<h5
														style={{
															textAlign: "left",
															marginLeft: "0px",
															paddingLeft: "0px",
															fontSize: "1em"
														}}
													>
														{this.state.draftChallengeCardTitle}
													</h5>
												</GridItem>
											</GridContainer>
										</CardHeader>
										<CardBody className={classnames({ [classes.cardBody]: true })}>
											<Accordion
												color={null}
												id="dashboardList"
												className={classes.cardBody}
												active={this.state.activeRow}
												collapses={this.getCollapseView4DraftChallenge()}
											/>
										</CardBody>
									</Card>
								</GridItem>
							) : null}

							{this.state.challengesWithPendingInvitation.length ? (
								<GridItem xs={12} sm={12} md={12} lg={this.state.showChallengeBuilder ? 6 : 6}>
									<Card profile={true} raised>
										<CardHeader className={classnames({ [classes.cardHead]: true })}>
											<GridContainer direction="row" justify="space-between" alignItems="center">
												<GridItem xs={12} sm={6} md={6}>
													<h5
														style={{
															textAlign: "left",
															marginLeft: "0px",
															paddingLeft: "0px",
															fontSize: "1em"
														}}
													>
														{this.state.challengesWithPendingInvitationsCardTitle}
													</h5>
												</GridItem>
											</GridContainer>
										</CardHeader>
										<CardBody className={classnames({ [classes.cardBody]: true })}>
											<Accordion
												color={null}
												id="dashboardList"
												className={classes.cardBody}
												active={this.state.activeRow}
												collapses={this.getCollapseView4ChallengesWithPendingInvitations()}
											/>
										</CardBody>
									</Card>
								</GridItem>
							) : null}
						</GridContainer>
					</div>
				) : (
					<div></div>
				)}
			</div>
		)
	}

	getCollapseView() {
		const { classes } = this.props
		let colorTheme = this.state.CanCreateChallenge ? null : "gmgTheme"
		let challenges = this.state.challenges
		if (challenges) {
			return challenges.map((item, key) => {
				if (!item.challengeDetails.tempParticipants) item.challengeDetails.tempParticipants = []
				let statusBGColor = "grey"
				let canManageChallenge = false
				if (item.challengeDetails.challengeStatus == constant.CHALLENGE_STATUS.ACTIVE)
					statusBGColor = "blue"
				if (item.challengeDetails.challengeStatus == constant.CHALLENGE_STATUS.COMPLETED)
					statusBGColor = "green"
				if (item.challengeDetails.challengeStatus == constant.CHALLENGE_STATUS.CLOSED)
					statusBGColor = "red"
				if (
					item.roles.find(
						(r) => r.role == "challengeAdmin" || r.role == "licenseeAdmin" || r.role == "superAdmin"
					)
				) {
					canManageChallenge = true
				}

				return {
					title: (
						<div>
							<GridContainer direction="column" alignItems="flex-start">
								<GridItem>
									<div style={{ margin: "5px 0px 10px 0px", textAlign: "left", fontSize: "1em" }}>
										{item.challengeDetails.title.toUpperCase()}
									</div>
								</GridItem>

								<GridItem>
									<div style={{ marginBottom: "5px" }}>
										<GridContainer direction="row" alignItems="center" justify="center">
											<GridItem>
												<span
													style={{
														padding: "5px 10px",
														borderRadius: "50px",
														backgroundColor: statusBGColor,
														color: "#FFF"
													}}
												>
													{item.challengeDetails.challengeStatus}
												</span>
											</GridItem>
											{item.phaseLinks && item.phaseLinks.length > 1 ? (
												<GridItem>
													<span
														style={{
															padding: "5px 10px",
															borderRadius: "50px",
															backgroundColor: "#3DAEFF",
															color: "#FFF"
														}}
													>
														{"multi-phase"}
													</span>
												</GridItem>
											) : null}
										</GridContainer>
									</div>
								</GridItem>

								<GridItem>
									<GridContainer direction="row" justify="center" alignItems="center">
										<GridItem>Phase: {item.challengeDetails.phaseTitle}</GridItem>
										<GridItem>
											Remaining:{" "}
											{item.remainingDays === Number.MAX_SAFE_INTEGER
												? "Coming soon"
												: item.remainingDays}
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</div>
					),
					content: (
						<Card color={colorTheme} className={classes.cardMarginReducer} raised={true}>
							<div className={classes.cardAction}>
								<CardBody
									className={classnames({ [classes.cardBody2]: this.state.CanCreateChallenge })}
								>
									<div key={key}>
										<List>
											<ListItem>
												<Divider variant="outset" />
											</ListItem>

											<ListItem>
												<GridContainer direction="row" justify="space-between" alignItems="center">
													<GridItem>
														<GridContainer direction="row" justify="flex-start" alignItems="center">
															<GridItem>
																<ManageChallengeMenu
																	challengeId={item.challengeId}
																	challenge={item}
																	urlSlug={item.urlSlug}
																	history={this.props.history}
																	anchorPosition="right"
																/>
															</GridItem>
														</GridContainer>
													</GridItem>
													<GridItem>
														<GridContainer direction="row" justify="flex-end" alignItems="center">
															{item.phaseLinks && item.phaseLinks.length > 1 ? (
																<GridItem>
																	<DropDownMenu
																		buttonText="Phases"
																		buttonIcon={BookIcon}
																		dropdownList={item.phaseLinks.map((pl) => pl.phaseTitle)}
																		handleClickOnItems={(selectedPhase) => {
																			console.log("Do something with this item, =", selectedPhase)
																			let phase = item.phaseLinks.find(
																				(pl) => pl.phaseTitle == selectedPhase
																			)
																			if (phase) {
																				this.props.history.push(
																					"/dashboard/challenge-details/" + phase.urlSlug
																				)
																			} else {
																				console.log("Invalid phase selection")
																			}
																		}}
																	/>
																</GridItem>
															) : null}

															{canManageChallenge && (
																<GridItem>
																	<ReactButton
																		color="success"
																		onClick={() => {
																			this.setState({
																				showChallengeBuilder: true,
																				selectedChallenge: item
																			})
																		}}
																	>
																		Add Phase
																	</ReactButton>
																</GridItem>
															)}
														</GridContainer>
													</GridItem>
												</GridContainer>
											</ListItem>

											<ListItem>
												<ListItemText
													primary={
														<div
															className={classnames({
																[classes.challengePropertiesTitle]: this.state.CanCreateChallenge,
																[classes.detailsVerticalBar]: true
															})}
														>
															SUMMARY
														</div>
													}
													secondary={
														<div
															className={classnames({
																[classes.challengeDescriptionBackground]: this.state
																	.CanCreateChallenge,
																[classes.challengeDescriptionColorThemed]: !this.state
																	.CanCreateChallenge
															})}
														>
															<FontOverridePatch>
																{ReactHtmlParser(item.challengeDetails.shortDescription || "")}
															</FontOverridePatch>
														</div>
													}
												/>
											</ListItem>

											<ListItem>
												<Divider variant="outset" />
											</ListItem>

											<ListItem>
												<ListItemText
													primary={
														<div
															className={classnames({
																[classes.challengePropertiesTitle]: this.state.CanCreateChallenge,
																[classes.detailsVerticalBar]: true
															})}
														>
															DESCRIPTION
														</div>
													}
													secondary={
														<div
															className={classnames({
																[classes.challengeDescriptionBackground]: this.state
																	.CanCreateChallenge,
																[classes.challengeDescriptionColorThemed]: !this.state
																	.CanCreateChallenge
															})}
														>
															<FontOverridePatch>
																{ReactHtmlParser(item.challengeDetails.description || "")}
															</FontOverridePatch>
														</div>
													}
												/>
											</ListItem>
											<ListItem>
												<Divider variant="outset" />
											</ListItem>
											{item.challengeDetails.sponsorsName && (
												<ListItem>
													<div>
														{" "}
														By{" "}
														<span style={{ fontStyle: "bold" }}>
															{" "}
															{" " + item.challengeDetails.sponsorsName}{" "}
														</span>{" "}
													</div>
												</ListItem>
											)}
										</List>
									</div>
									<GridContainer direction="row" justify="flex-end" allignment="flex-end">
										<GridItem>
											<ReactButton
												color="twitter"
												size="sm"
												onClick={() => this.handleOnClickLearnMore(item.urlSlug)}
											>
												Details
											</ReactButton>
										</GridItem>
										{item.challengeDetails.leaderboard &&
										item.challengeDetails.leaderboard.length &&
										this.state.canGetFinalLeaderboardForChallenge ? (
											<GridItem>
												<ReactButton
													color="info"
													size="sm"
													onClick={() =>
														this.props.history.push("/dashboard/leaderboard/" + item.challengeId)
													}
												>
													Leaderboard
												</ReactButton>
											</GridItem>
										) : null}
									</GridContainer>
								</CardBody>
							</div>
						</Card>
					)
				}
			})
		} else {
			return []
		}
	}

	getCollapseView4DraftChallenge = () => {
		const { classes } = this.props
		let colorTheme = this.state.CanCreateChallenge ? null : "gmgTheme"
		let challenges = this.state.draftChallenges
		if (challenges) {
			return challenges.map((item, key) => {
				if (!item.challengeDetails.tempParticipants) item.challengeDetails.tempParticipants = []
				let statusBGColor = "grey"
				if (item.challengeDetails.challengeStatus == constant.CHALLENGE_STATUS.ACTIVE)
					statusBGColor = "blue"
				if (item.challengeDetails.challengeStatus == constant.CHALLENGE_STATUS.COMPLETED)
					statusBGColor = "green"
				if (item.challengeDetails.challengeStatus == constant.CHALLENGE_STATUS.CLOSED)
					statusBGColor = "red"
				return {
					title: (
						<div style={{ color: "#FFF", padding: "10px 0px" }}>
							<GridContainer direction="column" alignItems="flex-start">
								<GridItem>
									<div style={{ margin: "5px 0px 10px 0px", textAlign: "left", fontSize: "1em" }}>
										{item.challengeDetails.title.toUpperCase()}
									</div>
								</GridItem>
								<GridItem>
									<div style={{ marginBottom: "5px" }}>
										<GridContainer direction="row" alignItems="center" justify="center">
											<GridItem>
												<span
													style={{
														padding: "5px 10px",
														borderRadius: "50px",
														backgroundColor: statusBGColor,
														color: "#FFF"
													}}
												>
													{item.challengeDetails.challengeStatus}
												</span>
											</GridItem>
											{item.phaseLinks && item.phaseLinks.length > 1 ? (
												<GridItem>
													<span
														style={{
															padding: "5px 10px",
															borderRadius: "50px",
															backgroundColor: "#3DAEFF",
															color: "#FFF"
														}}
													>
														{"multi-phase"}
													</span>
												</GridItem>
											) : null}
										</GridContainer>
									</div>
								</GridItem>
								<GridItem>
									<GridContainer direction="row" justify="center" alignItems="center">
										<GridItem>Phase: {item.challengeDetails.phaseTitle}</GridItem>
										<GridItem>
											Remaining:{" "}
											{item.remainingDays === Number.MAX_SAFE_INTEGER
												? "Coming soon"
												: item.remainingDays}
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</div>
					),
					content: (
						<Card className={classes.cardMarginReducer} raised={true}>
							<div className={classes.cardAction}>
								<CardBody className={classes.cardBody2}>
									<div key={key}>
										<List>
											<ListItem>
												<Divider variant="outset" />
											</ListItem>

											<ListItem>
												<GridContainer direction="row" justify="space-between" alignItems="center">
													<GridItem>
														<GridContainer direction="row" justify="flex-start" alignItems="center">
															<GridItem>
																<ManageChallengeMenu
																	challengeId={item.challengeId}
																	challenge={item}
																	urlSlug={item.urlSlug}
																	history={this.props.history}
																	anchorPosition="right"
																/>
															</GridItem>
														</GridContainer>
													</GridItem>
												</GridContainer>
											</ListItem>

											<ListItem>
												<ListItemText
													primary={
														<div
															className={
																classes.challengePropertiesTitle + " " + classes.detailsVerticalBar
															}
														>
															SUMMARY
														</div>
													}
													secondary={
														<div className={classes.challengeDescriptionBackground}>
															<FontOverridePatch>
																{ReactHtmlParser(item.challengeDetails.shortDescription || "")}
															</FontOverridePatch>
														</div>
													}
												/>
											</ListItem>

											<ListItem>
												<Divider variant="outset" />
											</ListItem>

											<ListItem>
												<ListItemText
													primary={
														<div
															className={
																classes.challengePropertiesTitle + " " + classes.detailsVerticalBar
															}
														>
															DESCRIPTION
														</div>
													}
													secondary={
														<div className={classes.challengeDescriptionBackground}>
															<FontOverridePatch>
																{ReactHtmlParser(item.challengeDetails.description || "")}
															</FontOverridePatch>
														</div>
													}
												/>
											</ListItem>
											<ListItem>
												<Divider variant="outset" />
											</ListItem>
											{item.challengeDetails.sponsorsName && (
												<ListItem>
													<div>
														{" "}
														By{" "}
														<span style={{ fontStyle: "bold" }}>
															{" "}
															{" " + item.challengeDetails.sponsorsName}{" "}
														</span>{" "}
													</div>
												</ListItem>
											)}
										</List>
									</div>
									<GridContainer direction="row" justify="flex-end" allignment="flex-end">
										<GridItem>
											<ReactButton
												color="twitter"
												size="sm"
												onClick={() => this.handleOnClickLearnMore(item.urlSlug)}
											>
												Details
											</ReactButton>
										</GridItem>
										{item.challengeDetails.leaderboard &&
										item.challengeDetails.leaderboard.length &&
										this.state.canGetFinalLeaderboardForChallenge ? (
											<GridItem>
												<ReactButton
													color="info"
													size="sm"
													onClick={() =>
														this.props.history.push("/dashboard/leaderboard/" + item.challengeId)
													}
												>
													Leaderboard
												</ReactButton>
											</GridItem>
										) : null}
									</GridContainer>
								</CardBody>
							</div>
						</Card>
					)
				}
			})
		} else {
			return []
		}
	}

	getCollapseView4ChallengesWithPendingInvitations = () => {
		const { classes } = this.props
		// let colorTheme = this.state.CanCreateOrUpdateEvaluationScore ? null : "gmgTheme"
		let colorTheme = "gmgTheme"
		let challenges = this.state.challengesWithPendingInvitation
		if (challenges) {
			return challenges.map((item, key) => {
				if (!item.challengeDetails.tempParticipants) item.challengeDetails.tempParticipants = []
				let statusBGColor = "grey"
				if (item.challengeDetails.challengeStatus == constant.CHALLENGE_STATUS.ACTIVE)
					statusBGColor = "blue"
				if (item.challengeDetails.challengeStatus == constant.CHALLENGE_STATUS.COMPLETED)
					statusBGColor = "green"
				if (item.challengeDetails.challengeStatus == constant.CHALLENGE_STATUS.CLOSED)
					statusBGColor = "red"
				return {
					title: (
						<div style={{ color: "#FFF", padding: "10px 0px" }}>
							<GridContainer direction="column" alignItems="flex-start">
								<GridItem>
									<div style={{ margin: "5px 0px 10px 0px", textAlign: "left", fontSize: "1em" }}>
										{item.challengeDetails.title.toUpperCase()}
									</div>
								</GridItem>
								<GridItem>
									<div style={{ marginBottom: "5px" }}>
										<GridContainer direction="row" alignItems="center" justify="center">
											<GridItem>
												<span
													style={{
														padding: "5px 10px",
														borderRadius: "50px",
														backgroundColor: statusBGColor,
														color: "#FFF"
													}}
												>
													{item.challengeDetails.challengeStatus}
												</span>
											</GridItem>
											{item.phaseLinks && item.phaseLinks.length > 1 ? (
												<GridItem>
													<span
														style={{
															padding: "5px 10px",
															borderRadius: "50px",
															backgroundColor: "#3DAEFF",
															color: "#FFF"
														}}
													>
														{"multi-phase"}
													</span>
												</GridItem>
											) : null}
										</GridContainer>
									</div>
								</GridItem>
								<GridItem>
									<GridContainer direction="row" justify="center" alignItems="center">
										<GridItem>Phase: {item.challengeDetails.phaseTitle}</GridItem>
										<GridItem>
											Remaining:{" "}
											{item.remainingDays === Number.MAX_SAFE_INTEGER
												? "Coming soon"
												: item.remainingDays}
										</GridItem>
									</GridContainer>
								</GridItem>
							</GridContainer>
						</div>
					),
					content: (
						<Card className={classes.cardMarginReducer} raised={true}>
							<div className={classes.cardAction}>
								<CardBody className={classes.cardBody2}>
									<div key={key}>
										<List>
											<ListItem>
												<Divider variant="outset" />
											</ListItem>

											<ListItem>
												<GridContainer direction="row" justify="space-between" alignItems="center">
													<GridItem>
														<GridContainer direction="row" justify="flex-start" alignItems="center">
															<GridItem>
																<ManageChallengeMenu
																	challengeId={item.challengeId}
																	challenge={item}
																	urlSlug={item.urlSlug}
																	history={this.props.history}
																	anchorPosition="right"
																/>
															</GridItem>
														</GridContainer>
													</GridItem>
												</GridContainer>
											</ListItem>

											<ListItem>
												<ListItemText
													primary={
														<div
															className={
																classes.challengePropertiesTitle + " " + classes.detailsVerticalBar
															}
														>
															SUMMARY
														</div>
													}
													secondary={
														<div className={classes.challengeDescriptionBackground}>
															<FontOverridePatch>
																{ReactHtmlParser(item.challengeDetails.shortDescription || "")}
															</FontOverridePatch>
														</div>
													}
												/>
											</ListItem>

											<ListItem>
												<Divider variant="outset" />
											</ListItem>

											<ListItem>
												<ListItemText
													primary={
														<div
															className={
																classes.challengePropertiesTitle + " " + classes.detailsVerticalBar
															}
														>
															DESCRIPTION
														</div>
													}
													secondary={
														<div className={classes.challengeDescriptionBackground}>
															<FontOverridePatch>
																{ReactHtmlParser(item.challengeDetails.description || "")}
															</FontOverridePatch>
														</div>
													}
												/>
											</ListItem>
											<ListItem>
												<Divider variant="outset" />
											</ListItem>
											{item.challengeDetails.sponsorsName && (
												<ListItem>
													<div>
														By{" "}
														<span style={{ fontStyle: "bold" }}>
															{" " + item.challengeDetails.sponsorsName}
														</span>
													</div>
												</ListItem>
											)}
										</List>
									</div>
									<GridContainer direction="row" justify="flex-end" allignment="flex-end">
										<GridItem>
											<ReactButton
												color="twitter"
												size="sm"
												onClick={() => this.handleOnClickLearnMore(item.urlSlug)}
											>
												Details
											</ReactButton>
										</GridItem>
										{item.challengeDetails.leaderboard &&
										item.challengeDetails.leaderboard.length &&
										this.state.canGetFinalLeaderboardForChallenge ? (
											<GridItem>
												<ReactButton
													color="info"
													size="sm"
													onClick={() =>
														this.props.history.push("/dashboard/leaderboard/" + item.challengeId)
													}
												>
													Leaderboard
												</ReactButton>
											</GridItem>
										) : null}
									</GridContainer>
								</CardBody>
							</div>
						</Card>
					)
				}
			})
		} else {
			return []
		}
	}
}

DashboardPage.propTypes = {
	classes: PropTypes.object.isRequired,
	loggedInUserData: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	resetBreadCrumbStack: PropTypes.func.isRequired,
	pushBreadCrumbStack: PropTypes.func.isRequired,
	popBreadCrubmStack: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			}),
		setRedirectOnError: (redirectTo = "") =>
			dispatch({ type: actionTypes.REDIRECT_ON_ERROR, payload: redirectTo })
	}
}

let extraStyle = (theme) => ({
	containerBackgroundImage: {
		backgroundImage: "url(" + backgroundImage + ")",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center center",
		backgroundSize: "cover",
		backgroundAttachment: "fixed"
	},
	challengePropertiesTitle: {
		backgroundColor: "#7E7575",
		color: "#FFFFFF",
		paddingLeft: "5px"
	},
	challengeDescriptionBackground: {
		//backgroundColor: "#FFFFFF",
		backgroundColor: "#7E7575",
		color: "#FFFFFF",
		padding: "5px 10px 5px 10px",
		textAlign: "justify",
		borderRadius: "3px"
	},
	challengeDescriptionColorThemed: {
		color: theme.palette.card.color.card + " !important",
		padding: "5px 10px 5px 10px",
		textAlign: "justify",
		borderRadius: "3px"
	},
	challengePropertiseSubTitle: {
		backgroundColor: "#7E7575",
		color: "#FFFFFF",
		paddingLeft: "10px"
	},
	detailsVerticalBar: {
		borderLeft: "5px solid #E25009"
	},
	peopleVerticalBar: {
		borderLeft: "5px solid #E26223"
	},
	statusVerticalBar: {
		borderLeft: "5px solid #E27743"
	},
	cardHead: {
		backgroundColor: "#605959",
		color: theme.palette.text.page || "#FFFFFF",
		paddingLeft: "20px",
		fontSize: "1.4em"
	},
	cardHeadInner: {
		backgroundColor: "#7E7575",
		color: "#FFFFFF",
		paddingTop: "0px",
		paddingBottom: "0px",
		marginTop: "-15px",
		marginBottom: "0px"
	},
	cardBody: {
		backgroundColor: "#605959",
		color: "#FFFFFF"
	},
	cardBody2: {
		backgroundColor: "#7E7575",
		color: "#FFFFFF",
		//marginTop: "0px",
		//paddingTop: "0px",
		padding: "0px",
		margin: "0px"
	},
	addChallengeButton: {
		color: "#ffffff",
		marginTop: "12px"
	},
	cardAction: {
		display: "block",
		textAlign: "initial"
	},
	cardMarginReducer: {
		marginTop: "0px",
		paddingTop: "0px",
		marginBottom: "0px",
		paddingBottom: "0px"
	},
	itemLink2: {
		transition: "all 300ms linear",
		//position: "relative",
		borderRadius: "5px",
		display: "block",
		backgroundColor: "#2DC7FF",
		fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
		fontSize: "1.2em",
		margin: "5px 0px",
		padding: "5px 5px",
		"&:hover": {
			outline: "none",
			backgroundColor: "#2D81FF",
			boxShadow: "none"
		},
		"&,&:hover,&:focus": {
			color: "inherit"
		}
	},
	manageEvaluationButtonStyle: {
		backgroundColor: "#7E7575"
	},
	collapseMenuStyle: {
		backgroundColor: "rgba(0,0,0,.5)"
	},
	hoverRoseColorStyle: {
		margin: "0px 5px 0px 5px",
		borderRadius: "3px",
		"&:hover,&:focus": {
			backgroundColor: "#e91e63"
		}
	}
})

let finalStyle = combineStyles(appStyle, extraStyle, sidebarStyle)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(finalStyle)(DashboardPage))
