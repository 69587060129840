/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import SweetAlert from "react-bootstrap-sweetalert"
import PropTypes from "prop-types"
// core components
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import CustomInputForChallengeBuilder from "components/CustomInput/CustomInputForChallengeBuilder.jsx"
import InputAdornment from "@material-ui/core/InputAdornment"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import Button from "../../components/CustomButtons/Button"
import NavPills from "../../components/NavPills/NavPills.jsx"
import Wizard from "components/Wizard/Wizard.jsx"
import StepSelectType from "../Forms/WizardSteps/StepSelectType.jsx" //edit here to create the pages
import StepAddPeople from "../Forms/WizardSteps/StepAddPeople.jsx" //edit here to create the pages
import StepDetails from "../Forms/WizardSteps/StepDetails.jsx" //edit here to create the pages
import Card from "../../components/Card/Card.jsx"
import CardHeader from "../../components/Card/CardHeader.jsx"
import CardBody from "../../components/Card/CardBody.jsx"
import { cardTitle } from "../../assets/jss/material-dashboard-pro-react.jsx"
import cookiesHelper from "../../cookiesHelper"
import constant from "../../constants"
import apolloFetcher from "../../apolloFetcher"
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.jsx"
import sweetAlertStyle from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.jsx"

import Divider from "@material-ui/core/Divider"
import Checkbox from "@material-ui/core/Checkbox"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

import authentication from "../../authentication"
import challengeServices from "dataServices/challengeServices"
import CardFooter from "components/Card/CardFooter"

import { connect } from "react-redux"
import actionTypes from "reduxjs/actionTypes"

import evaluationScoreServices from "dataServices/evaluationScoreServices"
import solutionServices from "dataServices/solutionServices"
import userServices from "dataServices/userServices"

const style = {
	infoText: {
		fontWeight: "300",
		margin: "10px 0 30px",
		textAlign: "center",
		color: "#FFFFFF"
	},
	cardHeaderText: {
		fontWeight: "300",
		margin: "10px 0 30px",
		textAlign: "center",
		color: "#FFFFFF"
	},
	submitButtonStyle: {
		backgroundColor: "#ff5722"
	},
	cardHeaderStyle: {
		backgroundColor: "#545252"
	},
	cardBodyStyle: {
		backgroundColor: "#605959",
		paddingTop: "50px"
	},
	cardFooterStyle: {
		backgroundColor: "#605959"
	},
	challengeBuilderFormBackgroundStyle: {
		backgroundColor: "#4C4A4A",
		// width: "75%",
		margin: "0 auto",
		padding: "10px 10px 10px 10px",
		marginBottom: "25px",
		boxShadow: "0px 5px 15px #000000"
	},
	textColor2: {
		color: "#FFF"
	},
	...customSelectStyle,
	...sweetAlertStyle
}

class CreateChallengePage extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			parentChallengeId: "",
			challenge: null,
			title: "",
			sponsorsName: "",
			shortDescription: "",
			description: "",
			externalUrl: "",
			prizeAmount: "",
			phaseTitle: "",
			challengeType: "",
			alert: null,
			show: false,
			challengeId: "",
			canCreateChallenge: false,
			parentChallenge: null,
			solverSelectionType: "",
			showBuilderForm: true,
			showUsersSelectionUI: false,
			participants: [],
			checked: []
		}
		this.hideAlert = this.hideAlert.bind(this)
		this.successAlert = this.successAlert.bind(this)
		this.createChallenge = this.createChallenge.bind(this)
	}

	makeStateDataFromChallenge = (challenge) => {
		if (!challenge) {
			return {
				parentChallenge: null,
				parentChallengeId: "",
				title: "",
				sponsorsName: "",
				shortDescription: "",
				description: "",
				prizeAmount: "",
				phaseTitle: "",
				challengeType: "",
				solverSelectionType: "",
				showBuilderForm: true,
				showUsersSelectionUI: false
			}
		}

		let solverSelectionType = "allUsers"

		if (
			challenge.challengeDetails.solverSelectionType &&
			challenge.challengeDetails.solverSelectionType.allUsers &&
			challenge.challengeDetails.solverSelectionType.selectedUsers
		)
			solverSelectionType = "both"
		else if (
			challenge.challengeDetails.solverSelectionType &&
			challenge.challengeDetails.solverSelectionType.selectedUsers
		)
			solverSelectionType = "selectedUsers"

		return {
			parentChallenge: challenge,
			parentChallengeId: challenge.challengeId,
			title: challenge.challengeDetails.title,
			sponsorsName: challenge.challengeDetails.sponsorsName,
			shortDescription: challenge.challengeDetails.shortDescription,
			description: challenge.challengeDetails.description,
			prizeAmount: challenge.challengeDetails.prizeAmount,
			phaseTitle: challenge.challengeDetails.phaseTitle,
			challengeType: challenge.challengeDetails.challengeType,
			solverSelectionType: solverSelectionType,
			showBuilderForm: true,
			showUsersSelectionUI: false
		}
	}

	fetchSolvers = (challengeId) => {
		return userServices
			.solvers(1, 1000000, challengeId)
			.then((solvers) => {
				console.log("solvers = ", solvers)
				if (!solvers) throw new Error("Error getting solo solvers")
				return solvers
			})
			.catch((err) => {
				console.log("Error fetching the solo solvers information, err =", err.message)
				return []
			})
	}

	fetchTeams = (challengeId) => {
		console.log("challengeId = ", challengeId)
		return challengeServices
			.getAllTeamsByChallenge(challengeId, 1, 1000000)
			.then((teams) => {
				console.log("teams = ", teams)
				if (!teams) throw new Error("Error getting teams")
				return teams
			})
			.catch((err) => {
				console.log("Error = ", err.message)
				return []
			})
	}

	fetchParticipants = (challengeId) => {
		console.log("challengeId = ", challengeId)
		let participants = []
		return Promise.all([this.fetchSolvers(challengeId), this.fetchTeams(challengeId)])
			.then((result) => {
				console.log("res = ", result)
				result[0].data.map((user) => {
					participants.push({
						userName: user.name,
						userId: user.userId,
						email: user.email
					})
				})
				result[1].data.map((team) => {
					participants.push({
						teamName: team.teamName,
						teamId: team.id
					})
				})

				return participants
			})
			.catch((err) => {
				console.log("Error getting participants list, err=", err.message)
				return participants
			})
	}

	componentDidUpdate(prevProps) {
		console.log("component update")
		if (!this.props.parentChallenge && prevProps.parentChallenge) {
			this.setState({ ...this.makeStateDataFromChallenge(null) })
			this.setState({ participants: [], checked: [] })
		}
		if (
			this.props.parentChallenge &&
			(!prevProps.parentChallenge ||
				this.props.parentChallenge.challengeId != prevProps.parentChallenge.challengeId)
		) {
			this.setState({ ...this.makeStateDataFromChallenge(this.props.parentChallenge) })

			this.fetchParticipants(this.props.parentChallenge.challengeId).then((participants) => {
				console.log("participants = ", participants)
				this.setState({ participants: participants, checked: [] })
			})
		}
	}

	successAlert() {
		this.setState({
			alert: (
				<SweetAlert
					success
					style={{ display: "block", marginTop: "-100px" }}
					title="Good job!"
					onConfirm={() => this.hideAlert()}
					onCancel={() => this.hideAlert()}
					confirmBtnCssClass={this.props.classes.button + " " + this.props.classes.success}
				>
					Challenge has been created successfully
				</SweetAlert>
			)
		})
	}

	hideAlert() {
		this.setState({
			alert: null
		})
	}

	handleSimple = (event) => {
		let fieldName = event.target.name
		let fieldValue = event.target.value

		if (fieldName == "title" || fieldName == "phaseTitle") {
			fieldValue = fieldValue.replace(/\//g, "")
		}
		this.setState({
			[fieldName]: fieldValue,
			[fieldName + "Changed"]: true
		})
	}

	handleSimpleForPrize = (event) => {
		console.log(event.target.value)
		let tempPrizeamount = event.target.value
		//remove existing comas from the string
		if (tempPrizeamount) {
			tempPrizeamount = tempPrizeamount.replace(/,/g, "")
			tempPrizeamount = tempPrizeamount.replace(/ /g, "")
		}
		if (isNaN(tempPrizeamount)) {
			return
		}
		while (tempPrizeamount.length > 0 && tempPrizeamount[0] == "0") {
			tempPrizeamount = tempPrizeamount.substr(1, tempPrizeamount.length)
		}

		//Convert string into an array
		let newPrizeAmount = tempPrizeamount.split("").reverse()
		let finalPrizeAmount = []
		//Update array with commas in the right place
		if (newPrizeAmount) {
			for (var i = 0; i < newPrizeAmount.length; i += 3) {
				for (var j = i; j < i + 3; j++) {
					finalPrizeAmount.push(newPrizeAmount[j])
				}
				finalPrizeAmount.push(",")
			}
			if (finalPrizeAmount[finalPrizeAmount.length - 1] == ",") finalPrizeAmount.pop()
		}

		//Convert array into a string
		tempPrizeamount = finalPrizeAmount.reverse().join("")
		this.setState({ [event.target.name]: tempPrizeamount })
	}

	handleChange = (event) => {
		this.setState({ [event.target.name]: event.target.value })
	}

	verifyPrizeAmount = (value) => {
		if (value) {
			value = value.replace(/,/g, "")
		}
		var prizeAmountRex = /^[0-9]*[.]?[0-9]+$/
		if (prizeAmountRex.test(value)) {
			return true
		}
		return false
	}

	createChallenge() {
		let title = this.state.title.trim()
		let shortDescription = this.state.shortDescription.trim()
		let phaseTitle = this.state.phaseTitle.trim()
		let sponsorsName = this.state.sponsorsName.trim()
		let externalUrl = this.state.externalUrl.trim()

		if (
			!title ||
			title == "" ||
			!sponsorsName ||
			sponsorsName == "" ||
			!shortDescription ||
			shortDescription == "" ||
			!this.state.description ||
			!this.state.challengeType ||
			!this.state.prizeAmount ||
			!phaseTitle ||
			phaseTitle == ""
		) {
			this.props.showAlert("warning", "Ooops!", "You can not enter empty value")
		} else if (!this.verifyPrizeAmount(this.state.prizeAmount)) {
			this.props.showAlert("warning", "Ooops!", "Please enter numeric value as prize amount")
		} else {
			const variables = {
				title: title,
				sponsorsName: sponsorsName,
				shortDescription: shortDescription,
				externalUrl: externalUrl,
				description: this.state.description,
				challengeType: this.state.challengeType,
				prizeAmount: this.state.prizeAmount,
				phaseTitle: phaseTitle
			}

			if (!this.state.parentChallenge) {
				this.props.setLoadingSpinner()
				challengeServices
					.isChallengeTitleUnique(this.state.title)
					.then((data) => {
						if (!data) throw new Error("There already one challenge exist with this title")
						return data
					})
					.then(() => {
						return challengeServices.createChallenge(variables)
					})
					.then((data) => {
						this.props.resetLoadingSpinner()
						this.props.updateChallenge()
						this.props.setMostRecentChallenge(data)
						this.setState(
							{
								challengeType: "",
								title: "",
								sponsorsName: "",
								description: "",
								shortDescription: "",
								externalUrl: "",
								prizeAmount: "",
								phaseTitle: ""
							},
							() => {
								this.props.hideChallengeBuilder()
							}
						)
					})
					.catch((err) => {
						console.log("Error creating challenge ", err.message)
						this.props.resetLoadingSpinner()
						this.props.showAlert("warning", "Ooops!", err.message)
					})
			} else {
				console.log("create phase api calling")
				variables.parentChallengeId = this.state.parentChallengeId
				if (!this.state.solverSelectionType) {
					this.props.showAlert("warning", "Ooops!", "Please select solvers")
				} else {
					variables.solverSelectionType = { allUsers: false, selectedUsers: false }
					if (this.state.solverSelectionType == "both") {
						variables.solverSelectionType = { allUsers: true, selectedUsers: true }
					} else {
						variables.solverSelectionType[this.state.solverSelectionType] = true
					}

					if (
						this.state.parentChallenge.phaseLinks.find((p) => p.phaseTitle == this.state.phaseTitle)
					) {
						this.props.showAlert("warning", "Opps!", "Phase title same as some past phase")
						return
					}

					this.props.setLoadingSpinner()
					challengeServices
						.createNewPhaseChallenge(variables)
						.then((data) => {
							this.props.resetLoadingSpinner()

							this.setState(
								{
									challengeId: data.challengeId,
									challenge: data,
									challengeType: "",
									title: "",
									sponsorsName: "",
									description: "",
									externalUrl: "",
									shortDescription: "",
									prizeAmount: "",
									phaseTitle: "",
									showBuilderForm: false,
									showUsersSelectionUI: variables.solverSelectionType.selectedUsers
								},
								() => {
									let message = ""
									if (!variables.solverSelectionType.selectedUsers) {
										this.props.updateChallenge()
										this.props.setMostRecentChallenge(data)
										this.props.hideChallengeBuilder()
										message = "The phase has been created successfully"
									} else {
										message =
											"The phase has been created successfully. You can import participants from active phase in next step."
									}
									this.props.showAlert("success", "Success", message)
								}
							)
						})
						.catch((err) => {
							console.log("Error creating phase ", err.message)
							this.props.resetLoadingSpinner()
							this.props.showAlert("warning", "Ooops!", err.message)
						})
				}
			}
		}
	}

	addSelectedUsersTeams = () => {
		console.log("checked = ", this.state.checked)
		let soloSolvers = [],
			teams = []
		if (this.state.checked.length == 0) {
			this.props.showAlert("warning", "Opps!", "No solvers or teams are selected")
			return
		}
		this.state.checked.map((leadIndex) => {
			let teamId = this.state.participants[leadIndex].teamId
			if (teamId) {
				teams.push(teamId)
			} else {
				let userId = this.state.participants[leadIndex].userId
				let name = this.state.participants[leadIndex].userName
				let email = this.state.participants[leadIndex].email
				soloSolvers.push({
					userId: userId,
					name: name,
					email: email
				})
			}
		})
		let variables = {
			challengeId: this.state.challengeId,
			soloSolvers: soloSolvers,
			teams: teams
		}

		this.props.setLoadingSpinner()
		challengeServices
			.addSelectedSolversTeams(variables)
			.then((data) => {
				console.log(data)
				this.props.resetLoadingSpinner()
				this.setState(
					{
						challengeId: "",
						parentChallenge: null,
						parentChallengeId: "",
						participants: [],
						checked: [],
						showUsersSelectionUI: false
					},
					() => {
						this.props.hideChallengeBuilder()
						this.props.updateChallenge()
						this.props.setMostRecentChallenge(data)
						this.props.showAlert(
							"success",
							"Success",
							"Selected participants have been added to the newly created phase"
						)
					}
				)
			})
			.catch((err) => {
				this.props.resetLoadingSpinner()
				console.log("Error adding solvers/teams, err=", err.message)
				this.props.showAlert("error", "Opps!", err.message)
			})
	}

	componentDidMount() {
		console.log("Create Challenge component Did Mount!")
		let stateObjct = this.makeStateDataFromChallenge(this.props.parentChallenge)
		this.setState({ ...stateObjct })

		if (this.props.parentChallenge) {
			this.fetchParticipants(this.props.parentChallenge.challengeId).then((participants) => {
				console.log("participants = ", participants)
				this.setState({ participants: participants, checked: [] })
			})
		}

		authentication
			.hasPermission(constant.Functionalities.CanCreateChallenge)
			.then((res) => {
				if (res == constant.Functionalities.CanCreateChallenge) {
					this.setState({ canCreateChallenge: true })
				}
			})
			.catch((err) => {
				console.log("Error =", err.message)
			})
	}

	render() {
		const { classes } = this.props
		return (
			<div>
				<div>
					{this.state.alert}
					{this.state.showBuilderForm && (
						<Card raised={true}>
							<CardHeader className={classes.cardHeaderStyle}>
								<h3 className={classes.cardHeaderText}>
									{this.state.parentChallenge ? "Phase" : "Challenge"} Builder
								</h3>
							</CardHeader>
							<CardBody className={classes.cardBodyStyle}>
								<div className={classes.challengeBuilderFormBackgroundStyle}>
									<h4 className={classes.infoText}>
										Add details for {this.state.parentChallenge ? "Phase" : "Challenge"}
									</h4>
									<CustomInputForChallengeBuilder
										className={classes.textColor}
										labelText="Title"
										id="title"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											onChange: this.handleSimple,
											name: "title",
											value: this.state.title
										}}
									/>
									<CustomInputForChallengeBuilder
										className={classes.textColor}
										labelText="Phase Title"
										id="phaseTitle"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											onChange: this.handleSimple,
											name: "phaseTitle",
											value: this.state.phaseTitle
										}}
									/>
									<CustomInputForChallengeBuilder
										className={classes.textColor}
										labelText="Sponsor's Name"
										id="sponsorsName"
										formControlProps={{
											fullWidth: true
										}}
										inputProps={{
											onChange: this.handleSimple,
											name: "sponsorsName",
											value: this.state.sponsorsName
										}}
									/>
									<CustomInputForChallengeBuilder
										labelText="Short Description"
										id="shortDescription"
										name="shortDescription"
										inputProps={{
											onChange: this.handleSimple,
											name: "shortDescription",
											value: this.state.shortDescription
										}}
										formControlProps={{
											fullWidth: true
										}}
									/>
									<CustomInputForChallengeBuilder
										labelText="Description"
										id="description"
										name="description"
										inputProps={{
											onChange: this.handleSimple,
											name: "description",
											multiline: true,
											rows: 5,
											value: this.state.description
										}}
										formControlProps={{
											fullWidth: true
										}}
									/>
									<CustomInputForChallengeBuilder
										labelText="External URL"
										id="externalUrl"
										name="externalUrl"
										inputProps={{
											onChange: this.handleSimple,
											name: "externalUrl",
											value: this.state.externalUrl
										}}
										formControlProps={{
											fullWidth: true
										}}
									/>
									<CustomInputForChallengeBuilder
										labelText="Prize Amount"
										id="prizeAmount"
										name="prizeAmount"
										inputProps={{
											onChange: this.handleSimpleForPrize,
											name: "prizeAmount",
											value: this.state.prizeAmount,
											startAdornment: (
												<InputAdornment position="start">
													<span style={{ color: "#FFFFFF" }}> $ </span>
												</InputAdornment>
											)
										}}
										formControlProps={{
											fullWidth: true
										}}
									/>
									{this.state.parentChallenge && (
										<React.Fragment>
											<h4 className={classes.infoText}>Solver Selection Type</h4>
											<FormControl component="fieldset" className={classes.formControl}>
												<RadioGroup
													aria-label="SolverSelectionType"
													name="solverSelectionType"
													className={classes.group}
													value={this.state.solverSelectionType}
													onChange={this.handleChange}
													row={true}
												>
													<FormControlLabel
														value="allUsers"
														control={<Radio />}
														label="All Users"
													/>

													<FormControlLabel
														value="selectedUsers"
														control={<Radio />}
														label="Selected Users"
													/>
													<FormControlLabel value="both" control={<Radio />} label="Both" />
												</RadioGroup>
											</FormControl>
										</React.Fragment>
									)}
									<h4 className={classes.infoText}> Challenge Type</h4>
									<FormControl component="fieldset" className={classes.formControl}>
										<RadioGroup
											aria-label="challengeType"
											name="challengeType"
											className={classes.group}
											value={this.state.challengeType}
											onChange={this.handleChange}
											row={true}
										>
											<FormControlLabel value="video" control={<Radio />} label="Video" />
											<FormControlLabel value="subjective" control={<Radio />} label="Subjective" />
											<FormControlLabel value="data" control={<Radio />} label="Data" />
											<FormControlLabel value="algorithm" control={<Radio />} label="Algorithm" />
										</RadioGroup>
									</FormControl>
								</div>
								<GridContainer direction="row" justify="space-between" alignItems="flex-end">
									<GridItem>
										<Button
											color="warning"
											onClick={() => {
												this.props.hideChallengeBuilder()
											}}
										>
											Cancel
										</Button>
									</GridItem>
									<GridItem>
										<Button
											color="success"
											disabled={
												!this.state.title ||
												!this.state.title.trim() ||
												!this.state.shortDescription ||
												!this.state.shortDescription.trim() ||
												!this.state.description ||
												!this.state.description.trim() ||
												!this.state.challengeType ||
												!this.state.prizeAmount ||
												!this.state.prizeAmount.trim() ||
												!this.state.phaseTitle ||
												!this.state.phaseTitle.trim() ||
												!this.state.sponsorsName ||
												!this.state.sponsorsName.trim()
											}
											onClick={() => {
												this.createChallenge()
											}}
										>
											Submit
										</Button>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					)}

					{this.state.showUsersSelectionUI && (
						<Card raised={true}>
							<CardHeader className={classes.cardHeaderStyle}>
								<h3 className={classes.cardHeaderText}>Select Solvers From Previous Challenge</h3>
							</CardHeader>
							<CardBody className={classes.cardBodyStyle}>
								<div
									className={classes.challengeBuilderFormBackgroundStyle + " " + classes.textColor}
								>
									{this.state.participants.length == 0 && (
										<h4 style={{ margin: "20px 20px", color: "#FFF" }}>
											No participants are found on previous phase
										</h4>
									)}
									{this.state.participants.length > 0 && (
										<List dense className={classes.root + " " + classes.textColor}>
											<ListItem>
												<GridContainer direction="row" justify="flex-start" alignItems="flex-start">
													<GridItem xs>
														<h6 className={classes.textColor2}>
															<strong>Solver / Team Name</strong>
														</h6>
													</GridItem>
													<GridItem xs>
														<h6 className={classes.textColor2}>
															<strong>Select</strong>
														</h6>
													</GridItem>
												</GridContainer>
											</ListItem>
											<Divider />
											{this.state.participants.map((value, index) => {
												const labelId = `checkbox-list-secondary-label-${index}`
												return (
													<ListItem key={index} button divider>
														<GridContainer direction="row" justify="flex-start" alignItems="center">
															<GridItem xs>
																{value.teamName ? (
																	<h6 className={classes.textColor2}> {value.teamName} </h6>
																) : (
																	<h6 className={classes.textColor2}> {value.userName} </h6>
																)}
															</GridItem>

															<GridItem xs className={classes.textColor2}>
																<Checkbox
																	edge="end"
																	className={classes.textColor2}
																	onChange={() => {
																		let checked = this.state.checked
																		if (checked.indexOf(index) == -1) checked.push(index)
																		else checked = checked.filter((v) => v != index)
																		this.setState({ checked: checked })
																	}}
																	checked={this.state.checked.indexOf(index) !== -1}
																	inputProps={{ "aria-labelledby": labelId }}
																/>
															</GridItem>
														</GridContainer>
													</ListItem>
												)
											})}
										</List>
									)}
								</div>
								<GridContainer direction="row" justify="space-between" alignItems="flex-end">
									<GridItem>
										<Button
											color="warning"
											onClick={() => {
												this.props.hideChallengeBuilder()
												this.props.updateChallenge()
												this.props.setMostRecentChallenge(this.state.challenge)
											}}
										>
											Cancel
										</Button>
									</GridItem>
									<GridItem>
										<Button
											disabled={this.state.checked.length == 0}
											color="success"
											onClick={this.addSelectedUsersTeams}
										>
											Add selected teams/solvers
										</Button>
									</GridItem>
								</GridContainer>
							</CardBody>
						</Card>
					)}
				</div>
			</div>
		)
	}
}

CreateChallengePage.propTypes = {
	classes: PropTypes.object.isRequired,
	loggedInUserData: PropTypes.object.isRequired,
	setLoggedInUserData: PropTypes.func.isRequired,
	setLoadingSpinner: PropTypes.func.isRequired,
	resetLoadingSpinner: PropTypes.func.isRequired,
	showAlert: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
	return {
		loggedInUserData: state.loggedInUserData,
		breadCrumbStack: state.breadCrumbStack
	}
}

const mapDispatchToProps = (dispatch) => {
	return {
		setLoggedInUserData: (data) =>
			dispatch({ type: actionTypes.LOGGEDIN_USER_DATA_SET, payload: data }),
		setLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_SET }),
		resetLoadingSpinner: () => dispatch({ type: actionTypes.LOADING_SPINNER_RESET }),
		resetBreadCrumbStack: () => dispatch({ type: actionTypes.RESET_BREAD_CRUMB_STACK }),
		pushBreadCrumbStack: (data) =>
			dispatch({ type: actionTypes.PUSH_BREAD_CRUMB_STACK, payload: data }),
		popBreadCrubmStack: () => dispatch({ type: actionTypes.POP_BREAD_CRUMB_STACK }),
		showAlert: (alertType, title, description) =>
			dispatch({
				type: actionTypes.SHOW_ALERT,
				payload: {
					alertType,
					title,
					description
				}
			})
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(CreateChallengePage))
