/* eslint-disable prettier/prettier */

import DashboardIcon from "@material-ui/icons/Dashboard";
import HomeIcon from "@material-ui/icons/Home";
import FaceIcon from "@material-ui/icons/AccountBox";
import MessageIcon from "@material-ui/icons/Message"
import ListIcon from "@material-ui/icons/FormatListNumbered";
import challengeListPageIcon from "@material-ui/icons/List";
import challengeDetailsPageIcon from "@material-ui/icons/LibraryBooks";
import PricingPageIcon from "@material-ui/icons/AttachMoney";
import SolversPageIcon from "@material-ui/icons/SupervisorAccount";
import ResourcePageIcon from "@material-ui/icons/ImportContacts";
import UserPageIcon from "@material-ui/icons/VerifiedUserRounded";

import LandingPage from "views/Pages/Landing.jsx";
import ChallengeListPage from "views/Pages/ChallengeListPage";
import ChallengeDetailsPage from "views/Pages/ChallengeDetailsPage.jsx";
import ChallengeDetailsPageRoot from "views/Pages/ChallengeDetailsPageRoot.jsx";
import JoinChallengePage from "views/Pages/JoinChallengePage.js";
import PublicChallengeDetailsPage from "views/Pages/PublicChallengeDetailsPage.jsx";
import EditProfilePage from "views/Pages/EditProfilePage.jsx";
import SelectFeaturedChallenges from "views/Pages/SelectFeaturedChallenges";
import ScoringCriteriaPage from "views/Pages/ChallengeManagement/ScoringCriteriaPage";
import ChallengeEvaluatorsPage from "views/Pages/ChallengeManagement/ChallengeEvaluatorsPage";
import ComingSoonPage from "views/Pages/ComingSoonPage.jsx";
import AboutPage from "views/Pages/AboutPage.jsx";
import NewsletterPage from "views/Pages/NewsletterPage.jsx";
import UnsubscribeNewsletter from "views/Pages/UnsubscribeEmail";
import DashboardPage from "./views/Pages/DashboardPage";
import ManagePeoplePage from "views/Pages/ManagePeople/ManagePeoplePage";
import AcceptManageChallengeInvitation from "views/Pages/ManagePeople/AcceptManageChallengeInvitation";
import SolversPage from "views/Pages/Dashboard/Solvers";
import SubmissionScoringUI from "views/Pages/SubmissionScoringUI/SubmissionScoringUI";
import SendGeneralBulkMailPage from "views/Pages/SendGeneralBulkMailPage";
import LeaderBoardPage from "views/Pages/LeaderBoardPage";
import ReportingPage from "views/Pages/ReportingPage";
import AcceptRejectTeamInvitationPage from "views/Pages/ManageTeam/AcceptRejectTeamInvitationPage";
import SearchPage from "views/Pages/SearchPage";
import EmailMessagingByChallengePage from "views/Pages/EmailMessagingByChallengePage";
import ManageForms from "views/Pages/ManageForms";
import UnsubscribeEmailFromChallengeUpdatePage from "views/Pages/OtherPages/UnsubscribeEmailFromChallengeUpdatePage";
import SubmissionsDashboard from "views/Pages/SubmissionsDashboard";
import NotificationsPage from "views/Pages/NotificationsPage";
import VerifyEmailPage from "views/Pages/VerifyEmailPage";
import SubmissionLeaderboardPage from "views/Pages/Dashboard/ChallengeDetails/SubmissionLeaderboard";
import Users from "views/Pages/Dashboard/Users";

var homeRoutes = [
	{
		path: "/index",
		name: "Home",
		rtlName: "عالتسعير",
		mini: "PP",
		rtlMini: "ع",
		component: LandingPage,
		layout: "/home",
		icon: HomeIcon,
		renderOnSidebar: true
	},
	{
		path: "/index",
		name: "Dashboard",
		rtlName: "عالتسعير",
		mini: "PP",
		rtlMini: "ع",
		component: DashboardPage,
		icon: DashboardIcon,
		layout: "/dashboard",
		renderOnSidebar: true
	},
	{
		path: "/featured-challenges",
		name: "Featured Challenges",
		rtlName: "تيالجدول الزمني",
		mini: "TT",
		rtlMini: "تي",
		component: SelectFeaturedChallenges,
		icon: challengeDetailsPageIcon,
		layout: "/dashboard",
		renderOnSidebar: false
	},
	{
		path: "/challenge-details/:challengeId",
		name: "Challenge Details",
		rtlName: "تيالجدول الزمني",
		mini: "TT",
		rtlMini: "تي",
		component: ChallengeDetailsPageRoot,
		icon: challengeDetailsPageIcon,
		layout: "/dashboard",
		renderOnSidebar: false
	},
	{
		path: "/challenge-details/:challengeId/:tabName",
		name: "Challenge Details",
		rtlName: "تيالجدول الزمني",
		mini: "TT",
		rtlMini: "تي",
		component: ChallengeDetailsPage,
		icon: challengeDetailsPageIcon,
		layout: "/dashboard",
		renderOnSidebar: false
	},
	{
		path: "/challenge-details/:challengeId",
		name: "Challenge Details",
		rtlName: "تيالجدول الزمني",
		mini: "TT",
		rtlMini: "تي",
		component: PublicChallengeDetailsPage,
		icon: challengeDetailsPageIcon,
		layout: "/home",
		renderOnSidebar: false
	},
	{
		path: "/challenges",
		name: "Challenges",
		rtlName: "عالتسعير",
		mini: "PP",
		rtlMini: "ع",
		component: ChallengeListPage,
		icon: challengeListPageIcon,
		layout: "/home",
		renderOnSidebar: true
	},
	{
		path: "/join-challenge/:challengeId",
		name: "Join Challenge",
		rtlName: "تيالجدول الزمني",
		mini: "TT",
		rtlMini: "تي",
		component: JoinChallengePage,
		icon: challengeDetailsPageIcon,
		layout: "/dashboard",
		renderOnSidebar: false
	},
	{
		path: "/pricing",
		name: "Pricing",
		rtlName: "تيالجدول الزمني",
		mini: "TT",
		rtlMini: "تي",
		component: AboutPage,
		icon: PricingPageIcon,
		layout: "/home",
		renderOnSidebar: false
	},
	{
		path: "/edit-profile",
		name: "Edit Profile",
		rtlName: "تيالجدول الزمني",
		mini: "TT",
		rtlMini: "تي",
		component: EditProfilePage,
		icon: FaceIcon,
		layout: "/dashboard",
		renderOnSidebar: false
	},
	{
		path: "/competition-regulations",
		name: "Competition Regulations",
		rtlName: "تيالجدول الزمني",
		mini: "TT",
		rtlMini: "تي",
		component: AboutPage,
		icon: challengeDetailsPageIcon,
		layout: "/home",
		renderOnSidebar: true
	},
	{
		path: "/solvers",
		name: "Solvers",
		rtlName: "تيالجدول الزمني",
		mini: "TT",
		rtlMini: "تي",
		component: SolversPage,
		icon: SolversPageIcon,
		layout: "/dashboard",
		renderOnSidebar: true
	},
	{
		path: "/users",
		name: "Users",
		rtlName: "تيالجدول الزمني",
		mini: "TT",
		rtlMini: "تي",
		component: Users,
		icon: UserPageIcon,
		layout: "/dashboard",
		renderOnSidebar: true
	},
	{
		path: "/how-we-work",
		name: "How We Work",
		rtlName: "تيالجدول الزمني",
		mini: "TT",
		rtlMini: "تي",
		component: AboutPage,
		icon: ResourcePageIcon,
		layout: "/home",
		renderOnSidebar: true
	},
	{
		path: "/about-ccc",
		name: "About CCC",
		rtlName: "تيالجدول الزمني",
		mini: "TT",
		rtlMini: "تي",
		component: AboutPage,
		icon: challengeDetailsPageIcon,
		layout: "/home",
		renderOnSidebar: false
	},
	{
		path: "/newsletter-signup",
		name: "About CCC",
		rtlName: "تيالجدول الزمني",
		mini: "TT",
		rtlMini: "تي",
		component: NewsletterPage,
		icon: challengeDetailsPageIcon,
		layout: "/home",
		renderOnSidebar: false
	},
	{
		path: "/newsletter-unsubscribe",
		name: "About CCC",
		rtlName: "تيالجدول الزمني",
		mini: "TT",
		rtlMini: "تي",
		component: UnsubscribeNewsletter,
		icon: challengeDetailsPageIcon,
		layout: "/home",
		renderOnSidebar: false
	},
	{
		path: "/team",
		name: "Team",
		rtlName: "تيالجدول الزمني",
		mini: "TT",
		rtlMini: "تي",
		component: ComingSoonPage,
		icon: challengeDetailsPageIcon,
		layout: "/home",
		renderOnSidebar: false
	},
	{
		path: "/contact-us",
		name: "Contact Us",
		rtlName: "تيالجدول الزمني",
		mini: "TT",
		rtlMini: "تي",
		component: AboutPage,
		icon: challengeDetailsPageIcon,
		layout: "/home",
		renderOnSidebar: false
	},
	{
		path: "/privacy",
		name: "Privacy",
		rtlName: "تيالجدول الزمني",
		mini: "TT",
		rtlMini: "تي",
		component: AboutPage,
		icon: challengeDetailsPageIcon,
		layout: "/home",
		renderOnSidebar: false
	},
	{
		path: "/terms",
		name: "Terms & Conditions",
		rtlName: "تيالجدول الزمني",
		mini: "TT",
		rtlMini: "تي",
		component: AboutPage,
		icon: challengeDetailsPageIcon,
		layout: "/home",
		renderOnSidebar: false
	},
	{
		path: "/cookie-policy",
		name: "Cookie Policy",
		rtlName: "تيالجدول الزمني",
		mini: "TT",
		rtlMini: "تي",
		component: AboutPage,
		icon: challengeDetailsPageIcon,
		layout: "/home",
		renderOnSidebar: false
	},
	{
		path: "/scoring-criteria/:challengeId",
		name: "Scoring Criteria",
		rtlName: "تيالجدول الزمني",
		mini: "SC",
		rtlMini: "تي",
		component: ScoringCriteriaPage,
		icon: challengeDetailsPageIcon,
		layout: "/dashboard",
		renderOnSidebar: false
	},
	{
		path: "/challenge-evaluators/:challengeId",
		name: "Challenge Evaluators",
		rtlName: "تيالجدول الزمني",
		mini: "CE",
		rtlMini: "تي",
		component: ChallengeEvaluatorsPage,
		icon: challengeDetailsPageIcon,
		layout: "/dashboard",
		renderOnSidebar: false
	},
	{
		path: "/manage-people/:challengeId",
		name: "Manage People",
		rtlName: "تيالجدول الزمني",
		mini: "MP",
		rtlMini: "تي",
		component: ManagePeoplePage,
		icon: challengeDetailsPageIcon,
		layout: "/dashboard",
		renderOnSidebar: false
	},
	{
		path: "/manage-challenge-invitation/",
		name: "Accept Manage Challenge Invitation",
		rtlName: "تيالجدول الزمني",
		mini: "MP",
		rtlMini: "تي",
		component: AcceptManageChallengeInvitation,
		icon: challengeDetailsPageIcon,
		layout: "/dashboard",
		renderOnSidebar: false
	},
	{
		path: "/team-invitation/",
		name: "Team Invitation",
		rtlName: "تيالجدول الزمني",
		mini: "MP",
		rtlMini: "تي",
		component: AcceptRejectTeamInvitationPage,
		icon: challengeDetailsPageIcon,
		layout: "/dashboard",
		renderOnSidebar: false
	},
	{
		path: "/reports/:challengeId",
		name: "Reports",
		rtlName: "تيالجدول الزمني",
		mini: "MP",
		rtlMini: "تي",
		component: ReportingPage,
		icon: challengeDetailsPageIcon,
		layout: "/dashboard",
		renderOnSidebar: false
	},
	{
		path: "/submission-scoring/:challengeId",
		name: "Submission Scoring",
		rtlName: "تيالجدول الزمني",
		mini: "SS",
		rtlMini: "تي",
		component: SubmissionScoringUI,
		icon: challengeDetailsPageIcon,
		layout: "/dashboard",
		renderOnSidebar: false
	},
	{
		path: "/send-general-mail",
		name: "Messages",
		rtlName: "تيالجدول الزمني",
		mini: "SS",
		rtlMini: "تي",
		component: SendGeneralBulkMailPage,
		icon: MessageIcon,
		layout: "/dashboard",
		renderOnSidebar: false
	},
	{
		path: "/leaderboard/:challengeId",
		name: "Leaderboard",
		rtlName: "تيالجدول الزمني",
		mini: "LB",
		rtlMini: "تي",
		component: LeaderBoardPage,
		icon: ListIcon,
		layout: "/dashboard",
		renderOnSidebar: false
	},
	{
		path: "/search",
		name: "Search Results",
		rtlName: "تيالجدول الزمني",
		mini: "SR",
		rtlMini: "تي",
		component: SearchPage,
		icon: ListIcon,
		layout: "/home",
		renderOnSidebar: false
	},
	{
		path: "/email-messaging/:challengeId",
		name: "Email Messaging",
		rtlName: "تيالجدول الزمني",
		mini: "EM",
		rtlMini: "تي",
		component: EmailMessagingByChallengePage,
		icon: challengeDetailsPageIcon,
		layout: "/dashboard",
		renderOnSidebar: false
	},
	{
		path: "/manage-forms/:challengeId",
		name: "Manage Forms",
		rtlName: "تيالجدول الزمني",
		mini: "MF",
		rtlMini: "تي",
		component: ManageForms,
		icon: challengeDetailsPageIcon,
		layout: "/dashboard",
		renderOnSidebar: false
	},
	{
		path: "/unsubscribe-challenge-update",
		name: "Unsubscribe Email",
		rtlName: "تيالجدول الزمني",
		mini: "UE",
		rtlMini: "تي",
		component: UnsubscribeEmailFromChallengeUpdatePage,
		icon: challengeDetailsPageIcon,
		layout: "/home",
		renderOnSidebar: false
	},
	{
		path: "/submissions/:challengeId",
		name: "Unsubscribe Email",
		rtlName: "تيالجدول الزمني",
		mini: "MF",
		rtlMini: "تي",
		component: SubmissionsDashboard,
		icon: challengeDetailsPageIcon,
		layout: "/dashboard",
		renderOnSidebar: false
	},
	{
		path: "/submissions/leaderboard/:challengeId",
		name: "Leaderboard",
		rtlName: "تيالجدول الزمني",
		mini: "Lb",
		rtlMini: "تي",
		component: SubmissionLeaderboardPage,
		icon: challengeDetailsPageIcon,
		layout: "/dashboard",
		renderOnSidebar: false
	},
	{
		path: "/notifications",
		name: "Notifications",
		rtlName: "تيالجدول الزمني",
		mini: "NF",
		rtlMini: "تي",
		component: NotificationsPage,
		icon: challengeDetailsPageIcon,
		layout: "/dashboard",
		renderOnSidebar: false
	},
	{
		path: "/verify-email",
		name: "Verify Email",
		rtlName: "تيالجدول الزمني",
		mini: "VE",
		rtlMini: "تي",
		component: VerifyEmailPage,
		icon: challengeDetailsPageIcon,
		layout: "/home",
		renderOnSidebar: false
	}
];

export default homeRoutes;
