import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

// @material-ui/icons
import ExpandMore from "@material-ui/icons/ExpandMore";

import accordionStyle from "assets/jss/material-dashboard-pro-react/components/accordionStyle.jsx";
import combineStyles from "../../utils/combineStyle";

class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: null
    };
  }
  handleChange = panel => (event, expanded) => {
    this.setState({
      active: expanded ? panel : -1
    });
  };

  componentWillReceiveProps(newProps) {
    this.setState({ active: newProps.active });
  }

  componentDidMount() {
    this.setState({
      active: this.props.active
    })
  }
  render() {
    const { classes, collapses } = this.props;
    return (
      <div className={classes.root}>
        {collapses.map((prop, key) => {
          return (
            <ExpansionPanel
              expanded={this.state.active === key}
              onChange={this.handleChange(key)}
              key={key}
              classes={{
                root: classes.expansionPanel,
                expanded: classes.expansionPanelExpanded
              }}
            >
              <ExpansionPanelSummary
                expandIcon={<ExpandMore />}
                classes={{
                  root: cx({ [classes.expansionPanelSummary]: true, [classes.expansionPanelSummary_challenge]: !this.props.color }),
                  expanded: ({ [classes.expansionPanelSummaryExpaned]: true, [classes.expansionPanelSummaryExpaned_challenge]: !this.props.color }),
                  content: classes.expansionPanelSummaryContent,
                  expandIcon: classes.expansionPanelSummaryExpandIcon
                }}
              >
                <h4 className={classes.title}>{prop.title}</h4>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className={cx({ [classes.expansionPanelDetails]: true, [classes.expansionPanelDetails_challenge]: !this.props.color })}>
                {prop.content}
              </ExpansionPanelDetails>
            </ExpansionPanel>
          );
        })}
      </div>
    );
  }
}

// Accordion.defaultProps = {
//   active: -1
// };

Accordion.propTypes = {
  classes: PropTypes.object.isRequired,
  // index of the default active collapse
  active: PropTypes.number,
  collapses: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.node
    })
  ).isRequired
};

let extraStyle = theme => ({
  expansionPanelSummary_challenge: {
    backgroundColor: "#7E7575 !important",
    color: "#FFFFFF !important"
  },
  expansionPanelDetails_challenge: {
    //padding: "15px 0px 0px !important",
    backgroundColor: "#7E7575 !important",
    color: "#FFFFFF !important"
  },
  expansionPanelExpanded_challenge: {
    marginBottom: "20px",
  },
  expansionPanel_challenge: {
    marginBottom: "20px",
  }
})
let finalStyle = combineStyles(accordionStyle, extraStyle);

export default withStyles(finalStyle)(Accordion);
